import { useCallback, useRef, useState } from 'react';
import {
  IPhotoCategory,
  IPhotoCategoryContest,
} from '@/shared/api/photo/getPhotoCategories/types';
import { useUpdateEffect } from 'react-use';
import { useGetHomePage } from '@/shared/hooks/useGetHomePage';
import { useLocale } from 'next-intl';
import { IntlMessageFormat } from 'intl-messageformat';

interface Params {
  contestCategory?: IPhotoCategory;
}

export const useVotingTitle = ({ contestCategory }: Params) => {
  const votesCounterRef = useRef(0);

  const locale = useLocale();

  const { data: homePage } = useGetHomePage({ lang: locale });

  const [title, setTitle] = useState(() =>
    contestCategory
      ? initContestTitle({
          contestCategory,
          template: homePage?.acf.contest_title_template ?? '',
        })
      : initTitle({
          homePageTitles: homePage?.acf.titles?.map((i) => i.title) ?? [],
        })
  );

  useUpdateEffect(() => {
    setTitle(
      contestCategory
        ? initContestTitle({
            contestCategory,
            template: homePage?.acf.contest_title_template ?? '',
          })
        : initTitle({
            homePageTitles: homePage?.acf.titles?.map((i) => i.title) ?? [],
          })
    );
  }, [contestCategory, homePage]);

  /* Меняем title после 3 голосов */
  const onAfterVote = useCallback(() => {
    if (votesCounterRef.current < 2) {
      return (votesCounterRef.current = votesCounterRef.current + 1);
    } else {
      setTitle((title) => {
        const homePageTitles = homePage?.acf.titles?.map((i) => i.title) ?? [];

        /* Исключаем из списка предыдущий title */
        if (homePageTitles.length > 1) {
          const currentTitleIndex = homePageTitles.findIndex(
            (i) => i === title
          );

          if (currentTitleIndex !== -1) {
            homePageTitles.splice(currentTitleIndex, 1);
          }
        }

        return contestCategory
          ? initContestTitle({
              contestCategory,
              template: homePage?.acf.contest_title_template ?? '',
            })
          : initTitle({ homePageTitles });
      });

      return (votesCounterRef.current = 0);
    }
  }, [contestCategory, homePage]);

  return { title, onAfterVote };
};

const initTitle = ({ homePageTitles }: { homePageTitles: string[] }) => {
  return homePageTitles
    .map((i) => ({ title: i, _random: Math.random() }))
    .sort((a, b) => b._random - a._random)[0].title;
};

const initContestTitle = ({
  contestCategory,
  template,
}: {
  contestCategory: IPhotoCategory;
  template: string;
}) => {
  return new IntlMessageFormat(template).format({
    title: (contestCategory.contest as IPhotoCategoryContest).contest_title,
  }) as string;
};
