import { useQuery } from '@tanstack/react-query';
import { getAvailableVoteCategories } from '@/shared/api/vote/getAvailableVoteCategories';
import { IPhotoCategory } from '@/shared/api/photo/getPhotoCategories/types';

type Params = Parameters<typeof getAvailableVoteCategories>[0];

export const useGetAvailableVoteCategories = (params: Params) => {
  return useQuery({
    queryKey: getUseGetAvailableVoteCategoriesQueryKey(params),
    queryFn: () => getAvailableVoteCategories(params),
    select: (data) =>
      data
        .filter((i) => isPhotoCategory(i) || isActiveContestCategory(i))
        .sort((a) => (isActiveContestCategory(a) ? -1 : 1)),
  });
};

export const getUseGetAvailableVoteCategoriesQueryKey = (params: Params) => [
  'getAvailableVoteCategories',
  params,
];

const isPhotoCategory = (photoCategory: IPhotoCategory) =>
  Boolean(!photoCategory.contest || Array.isArray(photoCategory.contest));
const isActiveContestCategory = (photoCategory: IPhotoCategory) =>
  Boolean(
    photoCategory.contest &&
      !Array.isArray(photoCategory.contest) &&
      Object.keys(photoCategory.contest).length > 0 &&
      photoCategory.contest.is_active
  );
