import { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { cva, VariantProps } from 'class-variance-authority';
import { DivProps } from 'react-html-props';
import clsx from 'clsx';
import s from './VotingImage.module.css';
import FavoritesButton from '@/entities/FavoritesButton/FavoritesButton';
import { IVoteItem } from '@/shared/api/vote/getVote/types';
import {
  cvaVotingGridLoader,
  cvaVotingGridLoaderWrapper,
} from '@/widgets/Voting/ui/VotingStyles';
import Image from 'next/image';
import { cvaImage } from '@/shared/ui/LazyImage/LazyImageStyles';
import Icon18 from '~/icons/18.svg';
import { useLocale } from 'next-intl';
import { useGetGlobalOptions } from '@/shared/hooks/useGetGlobalOptions';

interface Props extends VariantProps<typeof cvaRoot>, DivProps {
  isLoading: boolean;
  onLoadImage: () => void;
  voteItem?: IVoteItem | null;
  isColumn: boolean;
  sizes: {
    width: number;
    height: number;
  };
}

const VotingImage: FC<Props> = ({
  isLoading,
  onLoadImage,
  voteItem,
  side,
  sizes,
  className,
  onMouseEnter,
  onClick,
  isColumn,
  onMouseLeave,
  style,

  ...props
}) => {
  const locale = useLocale();
  const imageRef = useRef<HTMLImageElement | null>(null);

  const { data: globalOptions } = useGetGlobalOptions({ lang: locale });

  const [isForAdult, setIsForAdult] = useState(false);

  useEffect(() => {
    setIsForAdult(Boolean(voteItem?.is_for_adult));
  }, [voteItem?.is_for_adult]);

  useLayoutEffect(() => {
    if (imageRef.current && imageRef.current.complete) {
      onLoadImage();
    }
  }, [voteItem]);

  return (
    <div style={style} className={clsx(cvaRoot({ side, isColumn }), className)}>
      <div
        className={clsx(
          cvaImageWrapperItem({
            isWidthBigger: sizes.width / sizes.height > 1,
            isLoading,
          })
        )}
        onClick={isForAdult ? undefined : onClick}
        onMouseEnter={isForAdult ? undefined : onMouseEnter}
        onMouseLeave={isForAdult ? undefined : onMouseLeave}>
        {voteItem && !isForAdult ? (
          <FavoritesButton
            className={clsx(cvaFavorites(), s.favorites)}
            imageId={voteItem.id}
            initialIsFavorite={voteItem.is_favorite}
            onlyIcon
          />
        ) : null}
        {isForAdult && !isLoading ? (
          <button
            onClick={() => setIsForAdult(false)}
            className={clsx(cvaAdultButton(), s.adultButton)}>
            <Icon18 className={'w-2.4 h-2.4'} />
            {globalOptions?.settings_photo_hidden_text}
          </button>
        ) : null}
        <div
          className={clsx(
            cvaImageWrapper({
              hasImage: Boolean(voteItem?.thumbnail[0]),
              isForAdult,
            })
          )}
          {...props}>
          {voteItem?.thumbnail[0] ? (
            <Image
              ref={imageRef}
              className={clsx(
                cvaImage({ state: isLoading ? 'default' : 'loaded' }),
                cvaVoteImage()
              )}
              width={sizes.width}
              height={sizes.height}
              quality={100}
              src={voteItem.thumbnail[0]}
              alt={''}
              style={{ aspectRatio: `${sizes.width} / ${sizes.height}` }}
              onLoad={onLoadImage}
              priority
            />
          ) : (
            <div
              className={clsx(
                cvaImage({ state: isLoading ? 'default' : 'loaded' }),
                cvaVoteImage()
              )}
              style={{ aspectRatio: `${sizes.width} / ${sizes.height}` }}
            />
          )}
        </div>
      </div>

      {isLoading ? (
        <div className={cvaVotingGridLoaderWrapper()}>
          <div className={cvaVotingGridLoader()}></div>
        </div>
      ) : null}
    </div>
  );
};

const cvaRoot = cva(
  [
    'VotingImage-cvaRoot',
    'relative',
    'flex flex-col justify-center sm-max:!items-center',
    'max-h-[82rem] md-max:max-h-[46.7rem] sm-max:max-h-[32rem]',
  ],
  {
    variants: {
      side: {
        left: 'items-end',
        right: 'items-start',
      },
      isColumn: {
        true: '!items-center',
        false: 'md-max:min-h-[33.4rem] sm-max:min-h-0',
      },
    },
  }
);

const cvaImageWrapperItem = cva(
  [
    'VotingImage-cvaImageWrapperItem',
    'relative',
    'flex flex-col',
    'overflow-hidden',
    'rounded-[2.4rem] border-4 md-max:rounded-[1.6rem]',
    'duration-300',
  ],
  {
    variants: {
      isWidthBigger: {
        true: s.wrapperImageWidthBigger,
        false: s.wrapperImageHeightBigger,
      },
      isLoading: {
        true: 'border-[rgba(64,64,64,0.5)]',
        false: 'border-transparent hover:border-cPurple900',
      },
    },
  }
);

const cvaAdultButton = cva([
  'VotingImage-cvaAdultButton',
  'absolute-center z-[4]',
  'flex items-center gap-0.8',
  'p-1.2 pr-2 rounded-[10rem]',
  'font-semibold',
  'bg-black',
]);

const cvaFavorites = cva([
  'VotingImage-cvaFavorites',
  '!absolute !top-1.2 !right-1.2 !z-[2] md-max:!top-0.8 md-max:!right-0.8',
]);

const cvaImageWrapper = cva(['VotingImage-cvaImageWrapper', 'cursor-pointer'], {
  variants: {
    hasImage: {
      true: '',
      false: 'aspect-[auto_904/820]',
    },
    isForAdult: {
      true: 'blur-[2.4rem]',
      false: '',
    },
  },
});

const cvaVoteImage = cva([
  'VotingImage-cvaVoteImage',
  'md-max:min-w-[28rem] sm-max:min-w-[16.8rem]',
  'object-cover',
]);

export default VotingImage;
