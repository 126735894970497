import { axiosInstance } from '@/shared/api/axios';
import { API } from '@/shared/api/api';
import { IPhotoCategory } from '@/shared/api/photo/getPhotoCategories/types';

interface Params {
  lang: string;
}

export const getAvailableVoteCategories = async (params: Params) => {
  const response = await axiosInstance.get<IPhotoCategory[]>(
    API.getAvailableVoteCategories,
    { params }
  );

  return response.data;
};
