import { API } from '../../api';
import { axiosInstance } from '../../axios';
import { IHomePage } from '@/shared/api/pages/getHomePage/types';

interface Params {
  lang: string;
}

export const getHomePage = async (params?: Params) => {
  const result = await axiosInstance.get<IHomePage>(API.getHomePage, {
    params: {
      acf_format: 'standard',
      ...params,
    },
  });

  return result.data;
};
