import { API } from '../../api';
import { axiosInstance } from '../../axios';
import { HEADERS } from '@/shared/api/headers';

interface IParams {
  category_id?: number;
  special_contest_id?: number;
  photo_winner_id: number;
  photo_loser_id: number;
  is_draw?: boolean;
  lang: string;
}

export const vote = async ({ lang, ...body }: IParams) => {
  const result = await axiosInstance.post(API.vote, body, {
    params: { lang },
    headers: {
      ...HEADERS.contentTypeUrlencoded,
    },
  });

  return result.data;
};
