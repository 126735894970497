import { ReactNode } from 'react';

export function handleOpenPopup(content: ReactNode) {
  if ('openPopup' in window && typeof window.openPopup === 'function') {
    window.openPopup(content);
  }
}

// READ: Вызывать эту функцию, в любом месте где нужен функционал авторизации.
export function handleRequireAuth(callback?: () => void) {
  const token = JSON.parse(localStorage.getItem('token') ?? 'null') as
    | string
    | null;

  if (!token) {
    openAuthPopup();
  } else {
    // READ: Если зарегестрирован, вызывать callback
    // READ: ПРимер: handleRequireAuth(votePhoto)
    callback?.();
  }
}

export const openAuthPopup = () => {
  if ('openAuthPopup' in window && typeof window.openAuthPopup === 'function') {
    window.openAuthPopup();
  }
};
