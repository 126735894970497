import { FC, useMemo } from 'react';
import { cva } from 'class-variance-authority';
import clsx from 'clsx';
import Container from '@/shared/ui/Container/ui/Container';
import VotingContestHoverIcon from '~/icons/voting-contest-hover.svg';
import VotingHoverIcon from '~/icons/voting-hover.svg';
import { Heading } from '@/shared/ui/Heading/Heading';
import Button from '@/shared/ui/Button/Button';
import { useGetPhotoCategories } from '@/shared/hooks/useGetPhotoCategories';
import useQueryParam from '@/shared/hooks/useQueryParam';
import { useGetAvailableVoteCategories } from '@/shared/hooks/useGetAvailableVoteCategories';
import { useLocale } from 'next-intl';
import { useGetHomePage } from '@/shared/hooks/useGetHomePage';

interface Props {
  className?: string;
}

export const VotingOver: FC<Props> = ({ className }) => {
  const locale = useLocale();

  const { data: homePage } = useGetHomePage({ lang: locale });

  const [category] = useQueryParam<string | undefined>('category');

  const { data: contestCategories } = useGetPhotoCategories(
    {
      lang: locale,
    },
    {
      type: 'contest',
    }
  );

  const {
    data: availableVoteCategories,
    isFetching: isAvailableVoteCategoriesFetching,
  } = useGetAvailableVoteCategories({ lang: locale });

  const isContestActiveCategory = useMemo(() => {
    return Boolean(contestCategories?.find((i) => String(i.id) === category));
  }, [contestCategories]);

  return (
    <Container className={clsx(cvaRoot(), className)} size={'xl'}>
      <div className={cvaContainer()}>
        {isContestActiveCategory ? (
          <VotingContestHoverIcon className={cvaIcon()} />
        ) : (
          <VotingHoverIcon className={cvaIcon()} />
        )}

        <Heading className={cvaTitle()} level={'3'} size={'h3'}>
          {isContestActiveCategory
            ? homePage?.acf.contest_end.title ?? ''
            : homePage?.acf.voting_end}
        </Heading>

        {!isAvailableVoteCategoriesFetching &&
        availableVoteCategories?.length ? (
          <>
            <div className={cvaDescr()}>{homePage?.acf.contest_end.text}</div>
            <div className={cvaButtons()}>
              {availableVoteCategories?.map((i) => (
                <Button
                  key={i.id}
                  className={cvaButton()}
                  theme={'inverse'}
                  size={'sm'}
                  href={`/?category=${i.id}`}>
                  {i.name}
                </Button>
              ))}
              <Button className={cvaButton()} size={'sm'} href={`/`}>
                {homePage?.acf.contest_end.button_text_all_categories}
              </Button>
            </div>
          </>
        ) : null}
      </div>
    </Container>
  );
};

const cvaRoot = cva([
  'VotingOver-cvaRoot',
  'flex justify-center items-center',
  'flex-1',
]);

const cvaContainer = cva([
  'VotingOver-cvaContainer',
  'flex flex-col items-center',
  'flex-1 max-w-[42.8rem]',
  'my-6.4 p-2.4 rounded-[2.4rem] md-max:rounded-[1.6rem] md-max:my-3.2',
  'shadow-popover bg-cBlack',
]);

const cvaIcon = cva([
  'VotingOver-cvaIcon',
  'grow-0 shrink-0 w-[24.4rem] h-[24.4rem] md-max:w-[20rem] md-max:h-[20rem]',
]);

const cvaTitle = cva(['VotingOver-cvaTitle', 'mt-0.8', 'text-center']);

const cvaDescr = cva([
  'VotingOver-cvaDescr',
  'mt-4.8 md-max:mt-3.2',
  'text-2 text-center md-max:text-1.8',
]);

const cvaButtons = cva([
  'VotingOver-cvaButtons',
  'flex flex-col gap-1.6',
  'w-full',
  'mt-4.8 md-max:mt-3.2',
]);

const cvaButton = cva(['VotingOver-cvaButton', 'text-1.6-600']);
