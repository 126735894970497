'use client';
import { ComponentProps, FC, ReactNode } from 'react';
import { cva } from 'class-variance-authority';
import { useIsVisitedPage } from '@/shared/hooks/useIsVisitedPage';
import {
  IPhotoCategory,
  IPhotoCategoryContest,
} from '@/shared/api/photo/getPhotoCategories/types';
import clsx from 'clsx';
import { cvaHeaderTitle } from '@/widgets/Voting/ui/VotingStyles';
import { useGetHomePage } from '@/shared/hooks/useGetHomePage';
import { useLocale } from 'next-intl';
import { IntlMessageFormat } from 'intl-messageformat';
import ReactMarkdown from 'react-markdown';
import { LocalizedLink } from '@/shared/i18n/routing';

interface Props {
  className?: string;
  contestCategory?: IPhotoCategory;
  titleSlot?: ReactNode;
}

export const VotingHeader: FC<Props> = ({
  className,
  contestCategory,
  titleSlot,
}) => {
  const locale = useLocale();

  const { data: homePage } = useGetHomePage({ lang: locale });

  const isPageVisited = useIsVisitedPage(
    contestCategory
      ? `/contest/${(contestCategory.contest as IPhotoCategoryContest).ID}`
      : '/elo'
  );

  return (
    <div className={clsx(cvaRoot(), className)}>
      {titleSlot ? (
        <h4 className={cvaHeaderTitle()} suppressHydrationWarning>
          {titleSlot}
        </h4>
      ) : null}

      <div className={cvaDescr()}>
        {contestCategory ? (
          <ReactMarkdown
            className={cvaMarkdown()}
            components={{
              p: ({ children }) => <span>{children}</span>,
              // @ts-expect-error: ?
              a: ({
                href,
                className,
                ...props
              }: ComponentProps<typeof LocalizedLink>) => (
                <>
                  <LocalizedLink
                    className={cvaLink({ visited: isPageVisited })}
                    href={href ?? ''}
                    {...props}
                  />
                  <div className={cvaSpacer()} />
                </>
              ),
            }}>
            {
              new IntlMessageFormat(
                (contestCategory.contest as IPhotoCategoryContest)
                  .vote_description_template ?? '',
                locale
              ).format({
                title: `[${(contestCategory.contest as IPhotoCategoryContest).contest_title}](/contest/${(contestCategory.contest as IPhotoCategoryContest).ID})`,
              }) as string
            }
          </ReactMarkdown>
        ) : (
          <ReactMarkdown
            className={cvaMarkdown()}
            components={{
              p: ({ children }) => <span>{children}</span>,
              // @ts-expect-error: ?
              a: ({
                href,
                className,
                ...props
              }: ComponentProps<typeof LocalizedLink>) => (
                <>
                  <LocalizedLink
                    className={cvaLink({ visited: isPageVisited })}
                    href={href ?? ''}
                    {...props}
                  />
                  <div className={cvaSpacer()} />
                </>
              ),
            }}>
            {homePage?.acf.text_template ?? ''}
          </ReactMarkdown>
        )}
      </div>
    </div>
  );
};

const cvaRoot = cva([
  'VotingHeader-cvaRoot',
  'flex flex-col items-center',
  'mb-5.7 md-max:mb-2.4 sm-max:mb-1.2',
  'text-center',
]);

const cvaDescr = cva([
  'VotingHeader-cvaDescr',
  'sm-max:hidden',
  'md-max:max-w-[49.2rem]',
  'text-cWhite text-1.8',
]);

const cvaMarkdown = cva(['VotingHeader-cvaMarkdown', 'flex items-center']);

const cvaLink = cva(
  [
    'VotingHeader-cvaLink',
    'inline-block',
    'py-0.9 px-0.8',
    'rounded-lg',
    'text-1.6-600',
  ],
  {
    variants: {
      visited: {
        true: 'bg-cGray900',
        false: 'bg-cPurple700',
      },
    },
  }
);

const cvaSpacer = cva([
  'VotingHeader-cvaSpacer',
  'hidden md-max:block',
  'flex-1',
]);
