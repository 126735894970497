import { API } from '../../api';
import { axiosInstance } from '../../axios';
import { TVoting } from './types';

interface IParams {
  category_id?: number;
  lang: string;
}

export const getVote = async (params: IParams) => {
  const result = await axiosInstance.get<TVoting>(API.getVote, {
    params,
  });

  return result.data;
};
