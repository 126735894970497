import { QueryClient, useQuery } from '@tanstack/react-query';
import { getHomePage } from '@/shared/api/pages/getHomePage';

type Params = Parameters<typeof getHomePage>[0];

export const useGetHomePage = (params: Params) => {
  return useQuery({
    queryKey: getUseGetHomePageQueryKey(params),
    queryFn: () => getHomePage(params),
    staleTime: Infinity,
  });
};

export const prefetchGetHomePage = async (
  queryClient: QueryClient,
  params: Params
) => {
  await queryClient.prefetchQuery({
    queryKey: getUseGetHomePageQueryKey(params),
    queryFn: () => getHomePage(params),
  });
};

export const getUseGetHomePageQueryKey = (params: Params) => [
  'getHomePage',
  params,
];
